import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
const InputCounterLabel = styled.span(props => ({
    color: props.hasError ?
        props.theme.palette.error.main : props.theme.customColors.input.borderHover,
    fontWeight: 400,
    fontSize: 10,
}));
const LabelAsterisk = styled.span(props => ({
    marginLeft: props.theme.spacing_sizes.xs * 0.25,
    color: props.theme.palette.error.main,
    letterSpacing: '-0.084px',
}));
const InputLabel = ({ label, count, maxCount, focused, size = 'small', required = undefined, }) => {
    const InputLabelComponent = useMemo(() => {
        const isValueString = typeof count === 'number';
        const isCountDefined = typeof maxCount === 'number';
        const isLimitExceeded = isCountDefined && isValueString && count > maxCount;
        if (focused && isCountDefined && isValueString) {
            const counter = count ? `${count} / ${maxCount}` : maxCount;
            return (_jsxs(_Fragment, { children: [_jsxs("span", { children: [label, required && _jsx(LabelAsterisk, { children: "*" })] }), _jsx(InputCounterLabel, { size: size, hasError: isLimitExceeded, children: counter })] }));
        }
        if (isLimitExceeded) {
            const counter = `${count} / ${maxCount}`;
            return (_jsxs(_Fragment, { children: [_jsxs("span", { children: [label, required && _jsx(LabelAsterisk, { children: "*" })] }), _jsx(InputCounterLabel, { size: size, hasError: true, children: counter })] }));
        }
        return (label ? (_jsxs("span", { children: [label, required && _jsx(LabelAsterisk, { children: "*" })] })) : null);
    }, [focused, count, label, maxCount]);
    return InputLabelComponent;
};
export default InputLabel;
