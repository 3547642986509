var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { forwardRef, useCallback, useState, } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as EyeSlashIcon } from '@icons/wolfkit-light/eye-slash-light.svg';
import { ReactComponent as EyeIcon } from '@icons/wolfkit-light/eye-light.svg';
const getDisabledStyles = (theme, disabled) => (disabled ? ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.customColors.input.backgroundDisabled,
        svg: {
            cursor: 'default',
            '&:hover': {
                path: {
                    fill: theme.customColors.icons.primary,
                },
            },
        },
    },
    '& input.MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: theme.palette.text.primary,
    },
}) : ({}));
const InputStyled = styled(TextField)(props => (Object.assign({ '& .MuiOutlinedInput-root, &.MuiTextField-root.MuiFormControl-root': {
        width: 'inherit',
        gap: '0 16px',
    }, '& label.MuiInputLabel-root, & label.MuiInputLabel-root.Mui-disabled': {
        position: 'relative',
        top: 0,
        left: 0,
        marginBottom: '12px',
        transform: 'none',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: props.theme.palette.text.primary,
    }, legend: {
        visibility: 'hidden',
        width: 0,
    }, '& .MuiFormHelperText-root': {
        marginLeft: 0,
        marginTop: props.theme.spacing_sizes.xs,
        fontSize: '14px',
        lineHeight: '18px',
    }, '& div.MuiOutlinedInput-root': {
        backgroundColor: props.theme.customColors.surface.surface,
        paddingRight: '16px',
        height: '48px',
        svg: {
            cursor: 'pointer',
            path: {
                fill: props.theme.customColors.icons.primary,
            },
            '&:hover': {
                path: {
                    fill: props.theme.customColors.icons.hovered,
                },
            },
        },
    }, '& .MuiOutlinedInput-input': {
        padding: '12px 0 12px 16px',
    }, '& .MuiInputAdornment-root': {
        marginRight: 0,
    } }, getDisabledStyles(props.theme, props.disabled))));
const InputPasswordComponent = (_a, ref) => {
    var { notification = undefined, notificationStatus = undefined, disabled = false, 'data-testid': dataTestId } = _a, props = __rest(_a, ["notification", "notificationStatus", "disabled", 'data-testid']);
    const [isHidden, toggleHidden] = useState(true);
    const toggle = useCallback(() => {
        if (!disabled) {
            toggleHidden((prev) => !prev);
        }
    }, [toggleHidden, disabled]);
    const eyeIcon = isHidden ? _jsx(EyeSlashIcon, { "aria-label": 'EyeSlash', onClick: toggle }) : _jsx(EyeIcon, { "aria-label": 'Eye', onClick: toggle });
    return (_jsx(InputStyled, Object.assign({}, props, { "data-testid": dataTestId, ref: ref, type: isHidden ? 'password' : 'text', InputProps: {
            endAdornment: (_jsx(InputAdornment, { role: 'button', position: 'start', children: eyeIcon })),
        }, error: notificationStatus === 'error', helperText: notification, disabled: disabled, size: 'small' })));
};
const InputPassword = forwardRef(InputPasswordComponent);
export default InputPassword;
