var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { forwardRef, useImperativeHandle, useRef, useState, } from 'react';
import { DropdownMenuVirtual } from '@shared/ui/menus/DropdownMenu';
import Input from '@shared/ui/inputs/Input';
import getSearchMenuRefs from './utils';
const InputMenuContainer = styled.div();
const InputMenuComponent = (_a, outerRef) => {
    var _b;
    var { menuSize = undefined, menuItems, hideMenu = false, placement = 'bottom-start', zIndex = undefined, disablePortal = false, keepMenuMounted = true, transition = false, onClose = undefined, maxCount = undefined, menuHeight = 300 } = _a, props = __rest(_a, ["menuSize", "menuItems", "hideMenu", "placement", "zIndex", "disablePortal", "keepMenuMounted", "transition", "onClose", "maxCount", "menuHeight"]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const inputRef = useRef();
    const { inputMenuRef, inputRef: inputOuterRef } = getSearchMenuRefs(outerRef);
    const menuWidth = (_b = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth;
    const handleMenuClose = () => setIsMenuOpen(false);
    const handleMenuOpen = () => setIsMenuOpen(true);
    const handleInputFocus = (ev) => {
        if (!hideMenu) {
            handleMenuOpen();
        }
        if (props.onFocus) {
            props.onFocus(ev);
        }
    };
    const onMenuClose = (ev) => {
        if (onClose) {
            onClose(ev);
        }
        if (!hideMenu) {
            handleMenuClose();
        }
    };
    useImperativeHandle(inputMenuRef, () => ({
        open: handleMenuOpen,
        close: handleMenuClose,
    }), []);
    useImperativeHandle(inputOuterRef, () => inputRef === null || inputRef === void 0 ? void 0 : inputRef.current, []);
    return (_jsxs(InputMenuContainer, { children: [_jsx(Input, Object.assign({}, props, { onFocus: handleInputFocus, ref: inputRef || null })), _jsx(DropdownMenuVirtual, { items: menuItems, size: menuSize, isOpen: isMenuOpen, placement: placement, zIndex: zIndex, disablePortal: disablePortal, keepMounted: keepMenuMounted, transition: transition, onClose: onMenuClose, ref: inputRef || null, menuWidth: menuWidth, menuHeight: menuHeight, maxItemsCount: maxCount })] }));
};
/**
 * Generally its a Input component + DropdownMenu with some logic for open\close menu
 */
const InputMenu = forwardRef(InputMenuComponent);
export default InputMenu;
