var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, } from 'react';
import { useMaskito } from '@maskito/react';
import Input from '@shared/ui/inputs/Input';
// TODO research about forwarding ref and connecting with callbackref
const InputMaskedComponent = (_a, ref) => {
    var { mask, hidden = undefined, onChange = undefined } = _a, props = __rest(_a, ["mask", "hidden", "onChange"]);
    const inputRef = useMaskito({ options: { mask } });
    const handleInputChange = (ev) => {
        if (onChange) {
            onChange(ev);
        }
    };
    return (_jsx(Input, Object.assign({}, props, { type: hidden ? 'password' : undefined, InputLabelProps: { shrink: true }, onInput: handleInputChange, ref: inputRef })));
};
export default forwardRef(InputMaskedComponent);
